const PATH = 'api';

export const MS: any = {
  USERS: process.env.REACT_APP_USERS_MS,
  DEBTS: process.env.REACT_APP_DEBTS_MS,
  CHECKOUT: process.env.REACT_APP_CHECKOUT_MS,
  ONBOARDING: process.env.REACT_APP_ONBOARDING_MS,
  PDV: process.env.REACT_APP_PDV_MS,
  LINK: process.env.REACT_APP_LINK_MS,
  MONITORING: process.env.REACT_APP_MONITORING_MS,
};

export const API_URL_WITH_MS = (
  ms:
    | 'USERS'
    | 'DEBTS'
    | 'CHECKOUT'
    | 'ONBOARDING'
    | 'PDV'
    | 'LINK'
    | 'MONITORING'
) => `${MS[ms]}/${PATH}`;
